import api from '../API';
import { Conversation, ContentMessage } from '../types';

export default {
  writeContent(conversation: Conversation): Promise<ContentMessage> {
    return api
      .post('write-content', { conversation })
      .then(response => response.data);
  },
};
