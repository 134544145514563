var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-item",
    _vm._g(_vm._b({}, "q-item", _vm.$attrs, false), _vm.$listeners),
    [
      _c(
        "q-item-section",
        [
          _c("q-item-label", [_vm._v(_vm._s(_vm.label))]),
          _c(
            "div",
            { staticClass: "flex items-center text-caption" },
            [
              _c("div", [_vm._v(_vm._s(_vm.template.contentType.name))]),
              _c("q-separator", { attrs: { vertical: "", spaced: "" } }),
              _c("div", [_vm._v(_vm._s(_vm.template.documentType.name))]),
              _c("q-separator", { attrs: { vertical: "", spaced: "" } }),
              _c("div", [
                _vm._v(_vm._s(_vm._f("datetime")(_vm.template.createdAt))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }