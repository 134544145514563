










import { HasTaskMixin, ResourceLoader } from 'mycorems-app-shared';
import { Account } from '../types';
import repository from './account-repository';

export default HasTaskMixin.extend({
  components: { ResourceLoader },
  model: {
    prop: 'account',
    event: 'loaded',
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
    account: Object as () => Account,
  },
  watch: {
    accountId: {
      handler() {
        this.load();
      },
      immediate: true,
    },
  },
  methods: {
    load() {
      this.task = repository.find(this.accountId);
      this.task.then((account: Account) => {
        this.$emit('loaded', account);
      });
    },
  },
});
