var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isGranted("SAVE_CONTENT_TYPE")
    ? _c(
        "q-btn",
        _vm._b({ on: { click: _vm.add } }, "q-btn", _vm.$attrs, false)
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }