















import { Modal, ModalMixin, AsyncBtn } from 'mycorems-app-shared';
import { Document } from '../../types';
import { submit } from '../document-service';

interface Data {
  task: Promise<Document>|null,
}

export default ModalMixin.extend({
  components: {
    Modal,
    AsyncBtn,
  },
  props: {
    document: {
      type: Object as () => Document,
      required: true,
    },
  },
  data(): Data {
    return {
      task: null,
    };
  },
  methods: {
    submit(): void {
      this.task = submit(this.document.id);
      this.task.then((document: Document) => {
        this.$emit('ok', document);
        this.hide();
      });
    },
  },
});
