import api from '../API';
import { ContentType } from '../types';

export function add(
  name: string, color: string|null, devDuration: number|null,
): Promise<ContentType> {
  return api.post('content-types', {
    name,
    color,
    devDuration,
  });
}

export function update(
  id: number, name: string, color: string|null, devDuration: number|null,
): Promise<void> {
  return api.put(`content-types/${id}`, {
    name,
    color,
    devDuration,
  });
}

export function activate(id: number): Promise<void> {
  return api.put(`content-types/${id}/activate`);
}

export function deactivate(id: number): Promise<void> {
  return api.put(`content-types/${id}/deactivate`);
}
