



















































































import {
  HasTaskMixin, Loader, formatDateTime, ShowFinally, Message,
} from 'mycorems-app-shared';
import { ContentType, ContentTypeFilters, ContentTypeUser } from '../types';
import repository from './content-type-repository';
import { getHumanizedDurationLabel } from '.';
import SaveContentTypeBtn from './SaveContentTypeBtn.vue';
import ContentTypeStateToggle from './ContentTypeStateToggle.vue';
import ContentTypeUsersBtn from '../content-type-user/ContentTypeUsersBtn.vue';

interface Data {
  contentTypes: ContentType[],
  name: string|undefined,
  active: boolean,
}

export default HasTaskMixin.extend({
  components: {
    Loader,
    SaveContentTypeBtn,
    ContentTypeStateToggle,
    ContentTypeUsersBtn,
    ShowFinally,
    Message,
  },
  data(): Data {
    return {
      contentTypes: [],
      name: undefined,
      active: true,
    };
  },
  computed: {
    columns(): object[] {
      return [
        {
          name: 'name',
          label: 'Name',
          align: 'left',
          field: 'name',
        },
        {
          name: 'color',
          label: 'Color',
          align: 'left',
          field: 'color',
        },
        {
          name: 'devDuration',
          label: 'Development Duration',
          align: 'left',
          field: (row: ContentType) => (
            (row.devDuration !== null)
              ? getHumanizedDurationLabel(row.devDuration)
              : 'N/A'
          ),
        },
        {
          name: 'publishers',
          label: 'Publishers',
          align: 'center',
        },
        {
          name: 'createdAt',
          label: 'Created',
          align: 'left',
          field: (row: ContentType) => formatDateTime(row.createdAt),
        },
        {
          name: 'actions',
          align: 'right',
        },
      ];
    },
    filters(): ContentTypeFilters {
      return {
        name: this.name,
        active: this.active,
      };
    },
  },
  watch: {
    filters: {
      handler(): void {
        this.load();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    assignUser(contentTypeUser: ContentTypeUser): void {
      const index = this.contentTypes.findIndex(
        (ct: ContentType) => ct.id === contentTypeUser.contentTypeId,
      );
      if (index < 0) return;
      this.contentTypes[index].users.unshift(contentTypeUser);
    },
    unassignUser(contentTypeUser: ContentTypeUser): void {
      const index = this.contentTypes.findIndex(
        (ct: ContentType) => ct.id === contentTypeUser.contentTypeId,
      );
      if (index < 0) return;
      const ctUserIndex = this.contentTypes[index].users.findIndex(
        (ctUser: ContentTypeUser) => ctUser.id === contentTypeUser.id,
      );
      this.contentTypes[index].users.splice(ctUserIndex, 1);
    },
    add(contentType: ContentType): void {
      this.contentTypes.unshift(contentType);
    },
    update(contentType: ContentType): void {
      const index = this.contentTypes.findIndex((ct: ContentType) => (ct.id === contentType.id));
      if (index >= 0) {
        this.contentTypes.splice(index, 1, contentType);
      }
    },
    load(): void {
      this.task = repository.findAll(this.filters);
      this.task.then((contentTypes: ContentType[]) => {
        this.contentTypes = contentTypes;
      });
    },
  },
});
