var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-input",
    _vm._b(
      {
        attrs: {
          type: "number",
          value: _vm.humanizedDuration,
          label: _vm.label,
          title: _vm.label,
          outlined: "",
        },
        on: { input: _vm.setDevDuration },
        scopedSlots: _vm._u([
          {
            key: "after",
            fn: function () {
              return [
                _c("select-duration-unit", {
                  staticStyle: { width: "10rem" },
                  attrs: { value: _vm.unit },
                  on: {
                    input: function ($event) {
                      _vm.selectedUnit = $event
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "q-input",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }