














import { HasTaskMixin } from 'mycorems-app-shared';
import { DocumentType } from '../types';
import repository from './document-type-repository';

interface Data {
  documentTypes: DocumentType[],
}

export default HasTaskMixin.extend({
  model: {
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: 'Select Document Type',
    },
    value: [String, Object as () => DocumentType],
  },
  data(): Data {
    return {
      documentTypes: [],
    };
  },
  methods: {
    load(): void {
      this.task = repository.findAll();
      this.task.then((documentTypes: DocumentType[]) => {
        this.documentTypes = documentTypes;
      });
    },
  },
  created(): void {
    this.load();
  },
});
