















































import { Modal, ModalMixin, AsyncBtn } from 'mycorems-app-shared';
import { ContentMessage, Conversation } from '../types';
import repository from './writing-repository';
import { writerRoles, isAssistant } from '.';

interface Data {
  task: Promise<ContentMessage>|null,
  message: string|null,
  contentMessages: ContentMessage[],
}

export default ModalMixin.extend({
  components: { Modal, AsyncBtn },
  data(): Data {
    return {
      task: null,
      message: null,
      contentMessages: [],
    };
  },
  methods: {
    isSelectable(contentMessage: ContentMessage): boolean {
      return isAssistant(contentMessage.writerRole);
    },
    select(contentMessage: ContentMessage): void {
      this.$emit('ok', contentMessage.message);
      this.hide();
    },
    add(contentMessage: ContentMessage) {
      this.contentMessages.unshift(contentMessage);
    },
    clearInputMessage(): void {
      this.message = null;
    },
    send(): void {
      if (!this.message) return;
      const conversation: Conversation = {
        messages: [
          ...this.contentMessages,
          {
            writerRole: writerRoles.user,
            message: this.message,
          },
        ],
      };
      this.task = repository.writeContent(conversation);
      this.task.then((contentMessage: ContentMessage) => {
        if (this.message) {
          this.add({
            writerRole: writerRoles.user,
            message: this.message,
          });
        }
        this.add(contentMessage);
        this.clearInputMessage();
      });
    },
  },
});
