























import Vue from 'vue';
import { ContentType, ContentTypeFilters } from '../types';
import repository from './content-type-repository';

interface Data {
  isLoading: boolean,
  contentTypes: ContentType[],
  name: string,
}

export default Vue.extend({
  props: {
    label: {
      type: String,
      default: 'Select Content Type',
    },
    value: [Number, Array as () => number[]],
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data(): Data {
    return {
      isLoading: false,
      contentTypes: [],
      name: '',
    };
  },
  computed: {
    filteredOptions(): ContentType[] {
      if (!this.name) return this.contentTypes;
      return this.contentTypes.filter(ct => ct.name.toLowerCase().indexOf(this.name) >= 0);
    },
    filters(): ContentTypeFilters {
      return {
        name: this.name,
      };
    },
  },
  methods: {
    load() {
      this.isLoading = true;
      const task = repository.findAll(this.filters);
      task.then((contentTypes) => {
        this.contentTypes = contentTypes;
      });
      task.finally(() => {
        this.isLoading = false;
      });
    },
    filter(name, update) {
      update(() => {
        this.name = name;
      });
    },
    onClear() {
      if (this.multiple) this.$emit('input', []);
      else this.$emit('input', null);
    },
  },
  created() {
    this.load();
  },
});
