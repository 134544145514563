var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-select",
    _vm._b(
      {
        attrs: {
          value: _vm.value,
          options: _vm.options,
          "option-label": "name",
          label: _vm.label,
          loading: _vm.isLoading,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("change", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "selected-item",
            fn: function (scope) {
              return [
                _c("template-item", {
                  staticClass: "q-px-none",
                  attrs: { template: scope.opt, dense: "" },
                }),
              ]
            },
          },
          {
            key: "option",
            fn: function (scope) {
              return [
                scope.opt.group
                  ? _c(
                      "q-item",
                      _vm._g(
                        _vm._b(
                          { attrs: { disable: "" } },
                          "q-item",
                          scope.itemProps,
                          false
                        ),
                        scope.itemEvents
                      ),
                      [
                        _c(
                          "q-item-section",
                          [
                            _c(
                              "q-item-label",
                              { staticClass: "text-bold text-uppercase" },
                              [_vm._v(_vm._s(scope.opt.group))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "template-item",
                      _vm._g(
                        _vm._b(
                          {
                            staticClass: "q-ml-sm",
                            attrs: { template: scope.opt },
                          },
                          "template-item",
                          scope.itemProps,
                          false
                        ),
                        scope.itemEvents
                      )
                    ),
              ]
            },
          },
        ]),
      },
      "q-select",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }