var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tag is-medium",
      style: { backgroundColor: "" + _vm.color },
    },
    [
      _vm._t("prepend"),
      _c("span", [_vm._v(_vm._s(_vm.contentTypeName))]),
      _vm._t("append"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }