import api from '../API';
import { Template, Field } from '../types';

export function create(
  documentTypeId: string,
  contentTypeId: number,
  accountId: string|null,
  name: string|null,
  fields: Field[],
): Promise<Template> {
  return api.post(`content-types/${contentTypeId}/templates`, {
    documentTypeId,
    accountId,
    name,
    fields,
  });
}

export function updateFields(id: string, fields: Field[]): Promise<Field[]> {
  return api
    .put(`templates/${id}/fields`, { fields })
    .then(response => response.data);
}

export function deleteTemplate(id: string): Promise<void> {
  return api.delete(`templates/${id}`);
}
