var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("loader", { attrs: { task: _vm.task } }),
      _vm.document
        ? _c("document-toolbar", {
            attrs: { document: _vm.document, "shared-editor-toolbar": "" },
            on: { submitted: _vm.update, approved: _vm.update },
          })
        : _vm._e(),
      _vm.userId
        ? _c("user-loader", {
            attrs: { "user-id": _vm.userId },
            model: {
              value: _vm.user,
              callback: function ($$v) {
                _vm.user = $$v
              },
              expression: "user",
            },
          })
        : _vm._e(),
      _vm.user
        ? _c("account-loader", {
            attrs: { "account-id": _vm.user.accountId },
            model: {
              value: _vm.userAccount,
              callback: function ($$v) {
                _vm.userAccount = $$v
              },
              expression: "userAccount",
            },
          })
        : _vm._e(),
      _vm.document
        ? _c("auto-saving-doc-editor", {
            attrs: {
              resource: _vm.document,
              headings: _vm.documentHeadings,
              "save-action": _vm.updateFields,
              "track-changes": _vm.canTrackChanges,
              "manage-tracked-changes": _vm.canManageTrackedChanges,
              "last-save-text": "Document saved",
              user: _vm.user,
              "shared-toolbar": "",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }