var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("account-loader", {
        attrs: { "account-id": _vm.accountId },
        model: {
          value: _vm.account,
          callback: function ($$v) {
            _vm.account = $$v
          },
          expression: "account",
        },
      }),
      _c(
        "admin-layout",
        {
          attrs: { heading: "Content Administration", account: _vm.account },
          scopedSlots: _vm._u([
            {
              key: "side",
              fn: function () {
                return [
                  _c(
                    "router-link",
                    { attrs: { to: "/admin", "account-id": _vm.accountId } },
                    [
                      _c("nav-item", {
                        attrs: {
                          label: "Account Configuration",
                          icon: "fa fa-cog",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.isServiceProvider
                    ? _c(
                        "router-link",
                        {
                          attrs: {
                            to: "/admin/content-types",
                            "account-id": _vm.accountId,
                          },
                        },
                        [
                          _c("nav-item", {
                            attrs: {
                              label: "Content Types",
                              icon: "fas fa-layer-group",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isServiceProvider || _vm.isAgency
                    ? _c(
                        "router-link",
                        {
                          attrs: {
                            to: "/admin/templates",
                            "account-id": _vm.accountId,
                          },
                        },
                        [
                          _c("nav-item", {
                            attrs: {
                              label: "Templates",
                              icon: "fa fa-file-alt",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }