var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      ref: "modal",
      attrs: { title: _vm.title, persistent: "" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("async-btn", {
                attrs: {
                  label: _vm.saveBtnLabel,
                  task: _vm.task,
                  disable: !_vm.canSave,
                  color: "primary",
                },
                on: { click: _vm.save },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "q-gutter-md" },
        [
          _c("select-available-template", {
            attrs: {
              label: "Choose from an existing template",
              value: _vm.selectedTemplate,
              "account-id": _vm.accountId,
              outlined: "",
            },
            on: { change: _vm.selectTemplate },
          }),
          _c("q-separator"),
          _c("select-content-type", {
            attrs: { readonly: _vm.hasTemplate, outlined: "" },
            model: {
              value: _vm.contentTypeId,
              callback: function ($$v) {
                _vm.contentTypeId = $$v
              },
              expression: "contentTypeId",
            },
          }),
          _c("select-document-type", {
            attrs: {
              value: _vm.documentTypeId,
              label: "Select Template Type",
              readonly: _vm.hasTemplate,
              outlined: "",
            },
            on: { change: _vm.selectDocumentType },
          }),
          _c("q-input", {
            attrs: {
              label: "Name (Optional)",
              readonly: _vm.hasTemplate,
              outlined: "",
            },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
          _c("add-fields", {
            attrs: { fields: _vm.fields },
            on: {
              change: function ($event) {
                _vm.fields = $event
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }