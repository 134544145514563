













import Vue from 'vue';

export default Vue.extend({
  props: {
    headings: {
      type: Array as () => String[],
      default(): String[] {
        return [];
      },
    },
  },
});
