var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-select",
    _vm._b(
      {
        attrs: {
          label: _vm.label,
          value: _vm.value,
          options: _vm.documentTypes,
          "option-label": "name",
          "option-value": "id",
          loading: _vm.isLoading,
          "map-options": "",
        },
        on: {
          input: function ($event) {
            return _vm.$emit("change", $event)
          },
        },
      },
      "q-select",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }