import api from '../API';
import { DocumentType } from '../types';

export default {
  findAll(): Promise<DocumentType[]> {
    return api
      .get('document-types')
      .then(response => response.data);
  },
};
