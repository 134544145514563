import api from '../API';
import { ContentTypeUser } from '../types';

export function assign(contentTypeId: number, userId: string): Promise<ContentTypeUser> {
  return api
    .post(`content-types/${contentTypeId}/users`, {
      userId,
    }).then();
}

export function unassign(id: string): Promise<void> {
  return api.delete(`content-type-users/${id}`);
}
