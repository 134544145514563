var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isGranted("MANAGE_ACCOUNT_CONFIG")
    ? _c(
        "q-card",
        [
          _c(
            "q-toolbar",
            [
              _c("q-toolbar-title", [
                _vm._v("\n      Account Configuration\n    "),
              ]),
            ],
            1
          ),
          _c(
            "q-card-section",
            [
              _c("account-config-list", {
                attrs: { "account-id": _vm.accountId },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }