











import Vue from 'vue';
import isGranted from '../authorization/isGranted.js';
import ContentTypes from './ContentTypes.vue';

export default Vue.extend({
  components: { ContentTypes },
  methods: {
    isGranted,
  },
});
