























import {
  HasTaskMixin,
  AsyncSavingStatus,
  EntitySequenceSaver,
} from 'mycorems-app-shared';
import Document from '@/shared/document/Document.vue';
import { Field, User } from '../types';
import EditorHeader from './EditorHeader.vue';
import EditorFields from './EditorFields.vue';

interface Resource {
  id: string,
  fields: Field[],
}

interface SaveAction {
  (id: string, fields: Field[]): Promise<Field[]>,
}

interface Data {
  sequenceSaver: EntitySequenceSaver<Field>|null,
  timer: number|null,
}

export default HasTaskMixin.extend({
  components: {
    Document,
    EditorFields,
    EditorHeader,
    AsyncSavingStatus,
  },
  props: {
    resource: {
      type: Object as () => Resource,
      required: true,
    },
    saveAction: {
      type: Function as unknown as () => SaveAction,
      required: true,
    },
    headings: {
      type: Array as () => String[],
      default(): String[] {
        return [];
      },
    },
    trackChanges: {
      type: Boolean,
      default: false,
    },
    manageTrackedChanges: {
      type: Boolean,
      default: false,
    },
    sharedToolbar: {
      type: Boolean,
      default: false,
    },
    lastSaveText: String,
    user: Object as () => User,
  },
  data(): Data {
    return {
      sequenceSaver: null,
      timer: null,
    };
  },
  methods: {
    delayedSave(field: Field): void {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.sequenceSave(field);
      }, 2000);
    },
    sequenceSave(field: Field): void {
      this.update(field);
      if (!this.sequenceSaver) return;
      this.task = this.sequenceSaver
        .save([field])
        .then((updatedFields: Field[]) => {
          updatedFields.forEach(this.updateLastModified);
        });
    },
    update(field: Field) {
      const index = this.resource.fields.findIndex(f => f.id === field.id);
      if (index >= 0) {
        this.resource.fields.splice(index, 1, field);
      }
    },
    updateLastModified(field: Field) {
      const index = this.resource.fields.findIndex((f) => f.id === field.id);
      if (index >= 0) {
        const updatedField = {
          ...this.resource.fields[index],
          lastModifiedAt: field.lastModifiedAt,
        };
        this.resource.fields.splice(index, 1, updatedField);
      }
    },
    save(fields: Field[]): Promise<Field[]> {
      return this.saveAction(this.resource.id, fields);
    },
  },
  created(): void {
    this.sequenceSaver = new EntitySequenceSaver(this.save);
  },
});
