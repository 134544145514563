


























import _ from 'lodash';
import {
  Modal, ModalMixin, ColorPicker, AsyncBtn,
} from 'mycorems-app-shared';
import { ContentType } from '../types';
import { add, update } from './content-service';
import ContentTypeDurationInput from './ContentTypeDurationInput.vue';

interface Data {
  task: Promise<any>|null,
  name: string|null,
  color: string|null,
  devDuration: number|null,
}

export default ModalMixin.extend({
  components: {
    Modal,
    ColorPicker,
    ContentTypeDurationInput,
    AsyncBtn,
  },
  props: {
    contentType: Object as () => ContentType,
  },
  data(): Data {
    return {
      task: null,
      name: (this.contentType) ? this.contentType.name : null,
      color: (this.contentType) ? this.contentType.color : null,
      devDuration: (this.contentType) ? this.contentType.devDuration : null,
    };
  },
  computed: {
    contentTypeExists(): boolean {
      return (this.contentType !== undefined);
    },
    title(): string {
      if (this.contentTypeExists) return `Update ${this.contentType.name} Content Type`;
      return 'Add Content Type';
    },
    saveBtnLabel(): string {
      if (this.contentTypeExists) return 'Update';
      return 'Add';
    },
    canSave(): boolean {
      return (
        (this.name !== null)
        && (this.name !== '')
      );
    },
  },
  methods: {
    save(): void {
      if (this.contentTypeExists) {
        this.updateContentType();
      } else {
        this.addContentType();
      }
    },
    addContentType(): void {
      if (!this.name) return;
      this.task = add(this.name, this.color, this.devDuration);
      this.task.then((contentType: ContentType) => {
        this.$emit('ok', contentType);
        this.hide();
      });
    },
    updateContentType(): void {
      if (!this.contentType || !this.name) return;
      this.task = update(this.contentType.id, this.name, this.color, this.devDuration);
      this.task.then(() => {
        const contentType: ContentType = _.cloneDeep(this.contentType);
        if (this.name) {
          contentType.name = this.name;
        }
        contentType.color = this.color;
        contentType.devDuration = this.devDuration;
        this.$emit('ok', contentType);
        this.hide();
      });
    },
  },
});
