














































import _ from 'lodash';
import { HasTaskMixin, Loader, notify } from 'mycorems-app-shared';
import { AccountConfig, Config } from '../types';
import { update } from './account-config-service';
import repository from './account-config-repository';

interface Data {
  accountConfig: AccountConfig|null,
  config: Config,
}

export default HasTaskMixin.extend({
  components: {
    Loader,
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  data(): Data {
    return {
      accountConfig: null,
      config: {
        publishing: undefined,
        nudgeInDays: undefined,
      },
    };
  },
  computed: {
    configChanged(): boolean {
      return (
        (this.accountConfig !== null)
        && !_.isEqual(this.config, this.accountConfig.config)
      );
    },
  },
  watch: {
    accountId: {
      handler(): void {
        this.clear();
        this.load();
      },
      immediate: true,
    },
    config: {
      handler(): void {
        this.update();
      },
      deep: true,
    },
  },
  methods: {
    setNudgeDays(nudgeInDays: string): void {
      if (!nudgeInDays) return;
      this.config.nudgeInDays = parseInt(nudgeInDays, 10);
    },
    update(): void {
      if (!this.configChanged) return;
      this.task = update(this.accountId, this.config);
      this.task.then(() => {
        if (this.accountConfig) {
          this.accountConfig.config = _.cloneDeep(this.config);
        }
        notify.success('Account configuration updated.');
      });
    },
    clear(): void {
      this.accountConfig = null;
      this.config = {
        publishing: undefined,
        nudgeInDays: undefined,
      };
    },
    load(): void {
      this.task = repository.find(this.accountId);
      this.task.then((accountConfig: AccountConfig) => {
        this.accountConfig = accountConfig;
        const config = _.cloneDeep(accountConfig.config);
        if ((config !== null) && !_.isEmpty(config)) {
          this.config = config;
        }
      });
    },
  },
});
