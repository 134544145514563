var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sticky-page-toolbar", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-3 flex items-center" },
        [_vm._t("title")],
        2
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-9 flex items-center justify-between" },
        [_vm.sharedToolbar ? _c("froala-toolbar") : _vm._e(), _vm._t("right")],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }