import api from '../API';
import { Account, AccountFilters, Collection } from '../types';

export default {
  find(id: string): Promise<Account> {
    return api.get(`accounts/${id}`).then(res => res.data);
  },
  findClients(parentId: string, filters?: AccountFilters): Promise<Collection<Account>> {
    return api
      .get(`accounts/${parentId}/clients`, { params: filters })
      .then(res => ({
        total: parseInt(res.headers['x-total-items'], 10),
        items: res.data,
      }));
  },
};
