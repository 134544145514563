

























import Vue from 'vue';

export default Vue.extend({
  props: {
    contentTypeName: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
});
