










import Vue from 'vue';
import { openModal, notify } from 'mycorems-app-shared';
import isGranted from '../../authorization/isGranted.js';
import { Document } from '../../types';
import { isInfoGathering } from '../../document-type';
import ApproveDocumentConfirmModal from './ApproveDocumentConfirmModal.vue';

export default Vue.extend({
  props: {
    document: {
      type: Object as () => Document,
      required: true,
    },
  },
  computed: {
    isInfoGathering(): boolean {
      return (isInfoGathering(this.document.documentType));
    },
    label(): string {
      return (this.isInfoGathering) ? 'Complete' : 'Approve';
    },
  },
  methods: {
    approveWithConfirm(): void {
      openModal(ApproveDocumentConfirmModal, {
        parent: this,
        document: this.document,
      }).onOk((document: Document) => {
        this.$emit('approved', document);
        const docTitle = `${this.document.documentType.name} - ${this.document.title}`;
        notify.success(
          `${docTitle} document is ${(isInfoGathering(document.documentType)) ? 'completed' : 'approved'}.`,
        );
      });
    },
    isGranted,
  },
});
