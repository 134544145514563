import { Route } from 'vue-router';
import { recognition } from 'mycorems-app-accounts';
import adminRoutes from '../admin/routes';
import DocumentEditor from '@/document/DocumentEditor.vue';
import TemplateEditor from '@/template/TemplateEditor.vue';

export default [
  ...adminRoutes,
  {
    path: 'accounts/:accountId',
    component: () => import('./RouterView.vue'),
    props: true,
    children: [
      {
        path: '',
        component: () => import('../Home.vue'),
        props(route: Route) {
          return {
            accountId: route.params.accountId,
            userId: recognition.getUserId(),
          };
        },
        meta: {
          title: 'Content',
        },
      },
      {
        name: 'content.content_items_page',
        path: 'content-items',
        component: () => import('../content-item/ContentItemsPage.vue'),
        props(route: Route) {
          return {
            accountId: route.params.accountId,
            userId: recognition.getUserId(),
            upcoming: true,
          };
        },
        meta: {
          title: 'Content Items | Content',
        },
      },
      {
        name: 'content.documents_page',
        path: 'documents',
        component: () => import('../document/DocumentsPage.vue'),
        props(route: Route) {
          return {
            accountId: route.params.accountId,
            userId: recognition.getUserId(),
          };
        },
        meta: {
          title: 'Documents',
        },
      },
      {
        path: 'calendar/plan',
        component: () => import('../calendar/plan/PlanCalendar.vue'),
        props: true,
        meta: {
          title: 'Plan Calendar | Content',
        },
      },
      {
        path: 'documents/:id',
        component: DocumentEditor,
        props: (route: Route) => {
          return {
            id: route.params.id,
            userId: recognition.getUserId(),
          };
        },
        meta: {
          title: 'Document Editor',
        },
      },
      // This is for templates that belong to agencies.
      {
        name: 'content.agency_template_editor',
        path: 'templates/:id',
        component: TemplateEditor,
        props: true,
        meta: {
          title: 'Template Editor',
        },
      },
    ],
  },
  // This is for templates that belong to service provider.
  {
    name: 'content.global_template_editor',
    path: 'templates/:id',
    component: TemplateEditor,
    props: true,
    meta: {
      title: 'Template Editor',
    },
  },
];
