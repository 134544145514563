




















import Vue from 'vue';
import {
  durationUnit, getDuration, getHumanizedDuration, getDurationUnit,
} from '.';
import SelectDurationUnit from './SelectDurationUnit.vue';

interface Data {
  selectedUnit: string|null,
}

export default Vue.extend({
  components: {
    SelectDurationUnit,
  },
  props: {
    label: {
      type: String,
      default: 'Duration it takes in developing content for this type',
    },
    value: Number,
  },
  data(): Data {
    return {
      selectedUnit: null,
    };
  },
  computed: {
    unit(): string {
      if (this.selectedUnit) return this.selectedUnit;
      if (!this.value) return durationUnit.DAY;
      return getDurationUnit(this.value);
    },
    humanizedDuration(): number|undefined {
      if (!this.value) return undefined;
      return getHumanizedDuration(this.unit, this.value);
    },
  },
  watch: {
    selectedUnit(): void {
      if (!this.value) return;
      this.clear();
    },
  },
  methods: {
    setDevDuration(value: number): void {
      this.$emit('input', getDuration(this.unit, value));
    },
    clear(): void {
      this.$emit('input', null);
    },
  },
});
