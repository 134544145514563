var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-btn-dropdown",
    _vm._b(
      {
        attrs: { "disable-dropdown": _vm.isApproved, flat: "", "no-caps": "" },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c("document-status", { attrs: { document: _vm.document } }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "q-btn-dropdown",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "q-list",
        { attrs: { dense: "" } },
        [
          _c(
            "q-item",
            { staticClass: "no-padding" },
            [
              _c(
                "q-item-section",
                [
                  _c("submit-document-btn", {
                    attrs: { document: _vm.document, flat: "" },
                    on: {
                      submitted: function ($event) {
                        return _vm.$emit("submitted", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "q-item",
            { staticClass: "no-padding" },
            [
              _c(
                "q-item-section",
                [
                  _c("approve-document-btn", {
                    attrs: { document: _vm.document, flat: "" },
                    on: {
                      approved: function ($event) {
                        return _vm.$emit("approved", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }