

















import _ from 'lodash';
import { HasTaskMixin, notify } from 'mycorems-app-shared';
import isGranted from '../authorization/isGranted.js';
import { ContentType } from '../types';
import { activate, deactivate } from './content-service';

export default HasTaskMixin.extend({
  props: {
    contentType: {
      type: Object as () => ContentType,
      required: true,
    },
    label: String,
  },
  methods: {
    change(value: boolean): void {
      if (value) {
        this.activate();
      } else {
        this.deactivate();
      }
    },
    activate(): void {
      this.task = activate(this.contentType.id);
      this.task.then(() => {
        const ct = _.cloneDeep(this.contentType);
        ct.isActive = true;
        notify.success(`${ct.name} content type is active.`);
        this.$emit('changed', ct);
      });
    },
    deactivate(): void {
      this.task = deactivate(this.contentType.id);
      this.task.then(() => {
        const ct = _.cloneDeep(this.contentType);
        ct.isActive = false;
        notify.success(`${ct.name} content type is deactivated.`);
        this.$emit('changed', ct);
      });
    },
    isGranted,
  },
});
