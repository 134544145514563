export const durationUnit = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
};

const durationUnitValue = {};
durationUnitValue[durationUnit.DAY] = 1;
durationUnitValue[durationUnit.WEEK] = 7;
durationUnitValue[durationUnit.MONTH] = 30;

export function getDuration(unit: string, value: number): number {
  return durationUnitValue[unit] * value;
}

export function getDurationUnit(value: number): string {
  if (value % durationUnitValue[durationUnit.WEEK] === 0) return durationUnit.WEEK;
  if (value % durationUnitValue[durationUnit.MONTH] === 0) return durationUnit.MONTH;
  return durationUnit.DAY;
}

export function getHumanizedDuration(unit: string, value: number): number {
  const humanizedDuration = value / durationUnitValue[unit];
  return Math.round(humanizedDuration);
}

export function getHumanizedDurationLabel(value: number): string {
  const unit = getDurationUnit(value);
  const humanizedDuration = getHumanizedDuration(unit, value);
  return `${humanizedDuration} ${unit}${(humanizedDuration > 1) ? 's' : ''}`;
}
