var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-list",
    { attrs: { separator: "" } },
    [
      _c(
        "q-item",
        [
          _c(
            "q-item-section",
            [
              _c("q-item-label", [_vm._v("Content Publishing")]),
              _c("q-item-label", { attrs: { caption: "" } }, [
                _vm._v("Content publishing description here."),
              ]),
            ],
            1
          ),
          _c(
            "q-item-section",
            [
              _c("q-btn-toggle", {
                attrs: {
                  options: [
                    {
                      label: "Yes",
                      value: true,
                      toggleColor: "positive",
                    },
                    {
                      label: "No",
                      value: false,
                      toggleColor: "negative",
                    },
                  ],
                  spread: "",
                },
                model: {
                  value: _vm.config.publishing,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "publishing", $$v)
                  },
                  expression: "config.publishing",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "q-item",
        [
          _c(
            "q-item-section",
            [
              _c("q-item-label", [_vm._v("Nudge")]),
              _c("q-item-label", { attrs: { caption: "" } }, [
                _vm._v(
                  "\n        The days after which a client can be nudged to view submitted documents.\n      "
                ),
              ]),
            ],
            1
          ),
          _c(
            "q-item-section",
            [
              _c("q-input", {
                attrs: {
                  value: _vm.config.nudgeInDays,
                  label: "Enter the number of days",
                  type: "number",
                  outlined: "",
                },
                on: { input: _vm.setNudgeDays },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }