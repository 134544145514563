



















import Vue from 'vue';
import isGranted from '../authorization/isGranted.js';
import { Template, TemplatesTableElement } from '../types';
import SaveTemplateBtn from './SaveTemplateBtn.vue';
import TemplatesTable from './TemplatesTable.vue';

export default Vue.extend({
  components: {
    SaveTemplateBtn,
    TemplatesTable,
  },
  props: {
    accountId: String,
  },
  methods: {
    add(template: Template): void {
      (this.$refs.templatesTable as TemplatesTableElement).add(template);
    },
    isGranted,
  },
});
