var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("loader", { attrs: { task: _vm.task } }),
      _c(
        "q-table",
        _vm._b(
          {
            staticClass: "q-table-cell-vcenter no-padding",
            attrs: {
              data: _vm.contentTypes,
              columns: _vm.columns,
              "rows-per-page-options": [],
              square: "",
              "wrap-cells": "",
            },
            scopedSlots: _vm._u([
              {
                key: "top-row",
                fn: function () {
                  return [
                    _c(
                      "q-tr",
                      [
                        _c("q-td", { attrs: { colspan: "6" } }, [
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c("q-input", {
                                attrs: {
                                  label: "search by name",
                                  debounce: "500",
                                  "clear-icon": "fa fa-times",
                                  dense: "",
                                  filled: "",
                                  clearable: "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend",
                                    fn: function () {
                                      return [
                                        _c("q-icon", {
                                          attrs: {
                                            name: "fa fa-search",
                                            size: "xs",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.name,
                                  callback: function ($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name",
                                },
                              }),
                              _c("q-space"),
                              _c("q-btn-toggle", {
                                attrs: {
                                  options: [
                                    { label: "Active", value: true },
                                    { label: "Not Active", value: false },
                                  ],
                                  "toggle-color": _vm.active
                                    ? "positive"
                                    : "negative",
                                  flat: "",
                                },
                                model: {
                                  value: _vm.active,
                                  callback: function ($$v) {
                                    _vm.active = $$v
                                  },
                                  expression: "active",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "body-cell-color",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "q-td",
                      [
                        _c("q-icon", {
                          style: { color: row.color },
                          attrs: { name: "fa fa-circle", size: "md" },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "body-cell-publishers",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "q-td",
                      { staticClass: "text-center" },
                      [
                        _c("content-type-users-btn", {
                          attrs: {
                            "content-type": row,
                            label: row.users.length,
                            round: "",
                          },
                          on: {
                            assigned: _vm.assignUser,
                            unassigned: _vm.unassignUser,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "body-cell-actions",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "q-td",
                      { staticClass: "text-right" },
                      [
                        _c("save-content-type-btn", {
                          attrs: {
                            "content-type": row,
                            icon: "fa fa-pencil-alt",
                            round: "",
                            flat: "",
                          },
                          on: { updated: _vm.update },
                        }),
                        _c("content-type-state-toggle", {
                          attrs: { "content-type": row },
                          on: { changed: _vm.update },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "no-data",
                fn: function () {
                  return [
                    _c(
                      "show-finally",
                      { staticClass: "full-width", attrs: { task: _vm.task } },
                      [_c("message", [_vm._v("No content types found.")])],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "q-table",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }