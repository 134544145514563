












import Vue from 'vue';
import FullHeightContainer from '../layout/FullHeightContainer.vue';

export default Vue.extend({
  components: {
    FullHeightContainer,
  },
});
