var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    { ref: "modal", attrs: { title: "Chat With AI", persistent: "" } },
    [
      _c("q-input", {
        attrs: { label: "Send a message", outlined: "", autogrow: "" },
        on: { submit: _vm.send },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function () {
              return [
                _c("async-btn", {
                  attrs: {
                    task: _vm.task,
                    label: "Send",
                    icon: "fa fa-arrow-right",
                    rounded: "",
                    flat: "",
                  },
                  on: { click: _vm.send },
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.message,
          callback: function ($$v) {
            _vm.message = $$v
          },
          expression: "message",
        },
      }),
      _c(
        "div",
        { staticClass: "content-messages custom-scrollbar" },
        _vm._l(_vm.contentMessages, function (contentMessage) {
          return _c(
            "div",
            { key: contentMessage.message, staticClass: "q-my-sm" },
            [
              _c(
                "span",
                { staticClass: "text-overline text-uppercase q-mr-sm" },
                [_vm._v(_vm._s(contentMessage.writerRole))]
              ),
              _vm.isSelectable(contentMessage)
                ? _c("q-btn", {
                    attrs: {
                      icon: "fa fa-clone",
                      title: "Copy and use this message",
                      flat: "",
                      round: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.select(contentMessage)
                      },
                    },
                  })
                : _vm._e(),
              _c("p", [_vm._v(_vm._s(contentMessage.message))]),
              _c("q-separator", { attrs: { spaced: "" } }),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }