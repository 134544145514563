import Vue from 'vue';
import { quasarComponents } from 'mycorems-app-shared';
import './styles/quasar.styl';
import iconSet from 'quasar/icon-set/fontawesome-v5.js';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import Quasar, {
  QIcon,
  QBtn,
  QInput,
  QList,
  QItem,
  QItemSection,
  QItemLabel,
  QSelect,
  QCard,
  QCardSection,
  QCardActions,
  QSeparator,
  QMarkupTable,
  QTable,
  QTr,
  QTd,
  QLinearProgress,
  QDialog,
  QPopupProxy,
  QSpace,
  QTabs,
  QTab,
  QTabPanels,
  QTabPanel,
  QChip,
  QToggle,
  Notify,
  Dialog,
  QToolbar,
  QToolbarTitle,
  QExpansionItem,
  QBtnToggle,
} from 'quasar';

Vue.use(Quasar, {
  config: {},
  components: {
    QIcon,
    QBtn,
    QInput,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QSelect,
    QCard,
    QCardSection,
    QCardActions,
    QSeparator,
    QMarkupTable,
    QTable,
    QTr,
    QTd,
    QLinearProgress,
    QDialog,
    QPopupProxy,
    QSpace,
    QTabs,
    QTab,
    QTabPanels,
    QTabPanel,
    QChip,
    QToggle,
    QToolbar,
    QToolbarTitle,
    QExpansionItem,
    QBtnToggle,
    ...quasarComponents,
  },
  plugins: {
    Notify,
    Dialog,
  },
  iconSet,
});
