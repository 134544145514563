var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      ref: "modal",
      attrs: { title: "Confirmation" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "async-btn",
                _vm._b(
                  {
                    attrs: {
                      label: "Submit",
                      color: "primary",
                      task: _vm.task,
                    },
                    on: { click: _vm.submit },
                  },
                  "async-btn",
                  _vm.$attrs,
                  false
                )
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("p", { staticClass: "text-subtitle1" }, [
        _vm._v("Are you sure you want to submit this document?"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }