var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("add-content-type-btn", {
        staticClass: "q-mb-md",
        attrs: {
          label: "Add Content Type",
          icon: "fa fa-plus",
          color: "primary",
        },
        on: { added: _vm.add },
      }),
      _c("content-types-table", {
        ref: "contentTypesTable",
        attrs: { flat: "" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }