import axios from 'axios';

const api = axios.create();

// Base URL needs to be set a runtime to allow a single Docker image
// to run in different environments
function setBaseURL(request) {
  request.baseURL = window.env.MYCOREMS_CONTENT.API_URL;
  return request;
}

/* eslint-disable */
// Setting this option when creating the axios (although it's meant to)
// instance does not work so an interceptor must be used.
function sendCookies(config) {
  config.withCredentials = true;
  return config;
}
/* eslint-enable */

function fetchCreatedResource(response) {
  if (response.status !== 201) {
    return response;
  }
  if (Object.keys(response.data).length > 0) {
    return response.data;
  }
  const { location } = response.headers;
  return api
    .get(location)
    .then(res => res.data);
}

api.interceptors.request.use(setBaseURL);
api.interceptors.request.use(sendCookies);
api.interceptors.response.use(fetchCreatedResource);

export default api;
