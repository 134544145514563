























import Vue from 'vue';
import { HumanizeTime } from 'mycorems-app-shared';
import { Document } from '../../types';
import {
  isApproved,
  isSubmitted,
  getStatusLabel,
  getStatusIcon,
  getStatusColor,
} from '.';

export default Vue.extend({
  components: { HumanizeTime },
  props: {
    document: {
      type: Object as () => Document,
      required: true,
    },
  },
  computed: {
    isApproved(): boolean {
      return isApproved(this.document);
    },
    isSubmitted(): boolean {
      return isSubmitted(this.document);
    },
    icon(): string {
      return getStatusIcon(this.document);
    },
    color(): string {
      return getStatusColor(this.document);
    },
  },
  filters: {
    status: getStatusLabel,
  },
});
