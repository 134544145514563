



















































import {
  Modal, ModalMixin, Loader, Message, notify,
} from 'mycorems-app-shared';
import { SelectUser, recognition } from 'mycorems-app-accounts';
import { UserQueryFilters } from 'mycorems-app-accounts/src/types';
import { ContentType, ContentTypeUser } from '../types';
import { assign, unassign } from './content-type-user-service';

interface Data {
  assignTask: Promise<ContentTypeUser>|null,
  unassignTask: Promise<void>|null,
  userFilters: UserQueryFilters,
}

export default ModalMixin.extend({
  components: {
    Modal,
    Loader,
    SelectUser,
    Message,
  },
  props: {
    contentType: {
      type: Object as () => ContentType,
      required: true,
    },
  },
  data(): Data {
    return {
      assignTask: null,
      unassignTask: null,
      userFilters: {
        roles: [
          'content/publisher',
        ],
      },
    };
  },
  computed: {
    title(): string {
      return `Publishers Assigned to ${this.contentType.name}`;
    },
    accountId(): string|null {
      return recognition.getAccountId();
    },
    assignedUserIds(): string[] {
      return this.contentType.users.map((ctUser: ContentTypeUser) => ctUser.user.id);
    },
  },
  methods: {
    assignUser(userId: string): void {
      this.assignTask = assign(this.contentType.id, userId);
      this.assignTask.then((contentTypeUser: ContentTypeUser) => {
        this.$emit('ok', { assigned: contentTypeUser });
        this.showMessage(contentTypeUser);
      });
    },
    unassignUser(contentTypeUser: ContentTypeUser): void {
      this.unassignTask = unassign(contentTypeUser.id);
      this.unassignTask.then(() => {
        this.$emit('ok', { unassigned: contentTypeUser });
        this.showMessage(contentTypeUser, false);
      });
    },
    showMessage(contentTypeUser: ContentTypeUser, assigned: boolean = true) {
      const userName = `${contentTypeUser.user.firstName} ${contentTypeUser.user.lastName}`;
      if (assigned) {
        notify.success(`Assigned ${userName} to ${this.contentType.name}.`);
      } else {
        notify.success(`Unassigned ${userName} from ${this.contentType.name}.`);
      }
    },
  },
});
