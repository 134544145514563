var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-select",
    _vm._b(
      {
        attrs: {
          label: _vm.label,
          value: _vm.value,
          options: _vm.filteredOptions,
          disabled: _vm.isLoading,
          loading: _vm.isLoading,
          multiple: _vm.multiple,
          clearable: _vm.clearable,
          "clear-icon": "fa fa-times",
          "option-label": "name",
          "option-value": "id",
          "input-debounce": 0,
          "emit-value": "",
          "map-options": "",
          "use-input": !_vm.multiple && !_vm.value,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
          clear: _vm.onClear,
          filter: _vm.filter,
        },
      },
      "q-select",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }