var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-auto" },
      [_c("q-icon", { attrs: { name: _vm.icon, color: _vm.color } })],
      1
    ),
    _c(
      "div",
      { staticClass: "col q-ml-xs" },
      [
        _c("span", [_vm._v(_vm._s(_vm._f("status")(_vm.document)))]),
        _vm.isApproved
          ? _c(
              "q-tooltip",
              [
                _vm.document.approvedBy
                  ? _c("span", { staticClass: "q-mr-xs" }, [
                      _vm._v(
                        "\n        by " +
                          _vm._s(_vm.document.approvedBy.firstName) +
                          " " +
                          _vm._s(_vm.document.approvedBy.lastName) +
                          " |\n      "
                      ),
                    ])
                  : _vm._e(),
                _c("humanize-time", {
                  attrs: { time: _vm.document.approvedAt },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.isApproved && _vm.isSubmitted
          ? _c(
              "q-tooltip",
              [
                _vm.document.lastSubmittedBy
                  ? _c("span", { staticClass: "q-mr-xs" }, [
                      _vm._v(
                        "\n        last submitted by " +
                          _vm._s(_vm.document.lastSubmittedBy.firstName) +
                          " " +
                          _vm._s(_vm.document.lastSubmittedBy.lastName) +
                          " |\n      "
                      ),
                    ])
                  : _vm._e(),
                _c("humanize-time", {
                  attrs: { time: _vm.document.lastSubmittedAt },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }