<template>
  <editor v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default {
  components: {
    Editor: () => import('mycorems_app/form/FroalaEditor'),
  },
};
</script>
