








import Vue from 'vue';
import { notify, openModal } from 'mycorems-app-shared';
import DeleteTemplateConfirmationModal from './DeleteTemplateConfirmationModal.vue';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    deleteWithConfirm(): void {
      openModal(DeleteTemplateConfirmationModal, {
        id: this.id,
      }).onOk(() => {
        notify.success('Template deleted.');
        this.$emit('deleted');
      });
    },
  },
});
