import { Document } from '../../types';
import { isInfoGathering } from '../../document-type';

export function isApproved(document: Document): boolean {
  return (document.approvedBy !== null || document.approvedAt !== null);
}

export function isSubmitted(document: Document): boolean {
  return (document.lastSubmittedBy !== null || document.lastSubmittedAt !== null);
}

export function getStatusLabel(document: Document): string {
  if (isInfoGathering(document.documentType) && isApproved(document)) return 'Completed';
  if (isApproved(document)) return 'Approved';
  return (
    isSubmitted(document)
    && !isInfoGathering(document.documentType)
  ) ? 'Client Review' : 'In Progress';
}

export function getStatusIcon(document: Document): string {
  return (isApproved(document)) ? 'far fa-check-circle' : 'far fa-clock';
}

export function getStatusColor(document: Document): string {
  return (isApproved(document)) ? 'positive' : 'warning';
}
