var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("document", [
    _c(
      "div",
      { staticStyle: { margin: "3.5rem 3.5rem 0 3.5rem" } },
      [
        _vm.task
          ? _c("async-saving-status", {
              attrs: { tasks: [_vm.task], "last-save-text": _vm.lastSaveText },
            })
          : _vm._e(),
        _c("editor-header", { attrs: { headings: _vm.headings } }),
        _c(
          "div",
          { staticClass: "q-mt-xl q-mb-sm" },
          [
            _c("editor-fields", {
              attrs: {
                fields: _vm.resource.fields,
                "track-changes": _vm.trackChanges,
                "manage-tracked-changes": _vm.manageTrackedChanges,
                "shared-toolbar": _vm.sharedToolbar,
                user: _vm.user,
              },
              on: { change: _vm.delayedSave },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }