<template>
    <div class="full-height-container">
        <slot></slot>
    </div>
</template>

<style lang="scss">

    body.is-presenting {
        .full-height-container {
            height: 100vh !important;
        }
    }

    .full-height-container {
        @media print {
            height: auto !important;
        }
    }
</style>

<script>
export default {
  methods: {
    resize() {
      const { top } = this.$el.getBoundingClientRect();
      const offsetTop = top + window.scrollY;
      const windowHeight = window.innerHeight;
      const height = windowHeight - offsetTop;
      this.$el.style.height = `${height}px`;
    },
  },
  mounted() {
    this.resize();
    window.addEventListener('load', this.resize);
    window.addEventListener('resize', this.resize);
  },
  beforeDestroy() {
    window.removeEventListener('load', this.resize);
    window.removeEventListener('resize', this.resize);
  },
};
</script>
