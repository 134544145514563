















import { Modal, ModalMixin, AsyncBtn } from 'mycorems-app-shared';
import { Document } from '../../types';
import { isInfoGathering } from '../../document-type';
import { approve } from './../document-service';

interface Data {
  task: Promise<Document>|null,
}

export default ModalMixin.extend({
  components: {
    Modal,
    AsyncBtn,
  },
  props: {
    document: {
      type: Object as () => Document,
      required: true,
    },
  },
  data(): Data {
    return {
      task: null,
    };
  },
  computed: {
    isInfoGathering(): boolean {
      return isInfoGathering(this.document.documentType);
    },
    confirmMessage(): string {
      if (this.isInfoGathering) return 'Are you sure you want to complete this document?';
      return 'Are you sure you want to approve this document?';
    },
    btnLabel(): string {
      return (this.isInfoGathering) ? 'Complete' : 'Approve';
    },
  },
  methods: {
    approve(): void {
      this.task = approve(this.document.id);
      this.task.then((document: Document) => {
        this.$emit('ok', document);
        this.hide();
      });
    },
  },
});
