import api from '../API';
import { ContentType, ContentTypeFilters } from '../types';

export default {
  findAll(filters: ContentTypeFilters): Promise<ContentType[]> {
    return api
      .get('content-types', { params: filters })
      .then(response => response.data);
  },
};
