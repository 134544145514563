var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.isGranted("ENABLE_DISABLE_CONTENT_TYPE")
        ? _c("q-toggle", {
            attrs: {
              value: _vm.contentType.isActive,
              label: _vm.label,
              color: "positive",
            },
            on: { input: _vm.change },
          })
        : _vm._e(),
      _vm.isLoading
        ? _c("q-btn", { attrs: { loading: _vm.isLoading, flat: "" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }