













import Vue from 'vue';
import { ContentType, ContentTypesTableElement } from '../types';
import AddContentTypeBtn from './SaveContentTypeBtn.vue';
import ContentTypesTable from './ContentTypesTable.vue';

export default Vue.extend({
  components: {
    AddContentTypeBtn,
    ContentTypesTable,
  },
  methods: {
    add(contentType: ContentType): void {
      (this.$refs.contentTypesTable as ContentTypesTableElement).add(contentType);
    },
  },
});
