











import Vue from 'vue';
import { durationUnit } from '.';

export default Vue.extend({
  props: {
    label: {
      type: String,
      default: 'Duration',
    },
    value: String,
  },
  computed: {
    options(): string[] {
      return [
        durationUnit.DAY,
        durationUnit.WEEK,
        durationUnit.MONTH,
      ];
    },
  },
});
