













import Vue from 'vue';
import isGranted from '../authorization/isGranted.js';
import AccountConfigList from './AccountConfigList.vue';

export default Vue.extend({
  components: {
    AccountConfigList,
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  methods: {
    isGranted,
  },
});
