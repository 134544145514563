import Admin from './Admin.vue';
import ContentTypesCard from '../content-type/ContentTypesCard.vue';
import TemplatesCard from '../template/TemplatesCard.vue';
import AccountConfigCard from '../account-config/AccountConfigCard.vue';

export default [
  {
    path: 'accounts/:accountId/admin',
    component: Admin,
    props: true,
    children: [
      {
        path: '',
        component: AccountConfigCard,
        props: true,
      },
      {
        path: 'content-types',
        component: ContentTypesCard,
      },
      {
        path: 'templates',
        component: TemplatesCard,
        props: true,
      },
    ],
  },
];
