var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "h5",
    { staticClass: "text-h5 text-center text-uppercase" },
    [
      _vm._l(_vm.headings, function (heading) {
        return _c("span", { key: heading }, [
          _c("span", [_vm._v(_vm._s(heading))]),
          _c("span", { staticClass: "text-grey-5 q-mx-sm" }, [_vm._v("|")]),
        ])
      }),
      _c("span", [_vm._v("Core Online Marketing")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }