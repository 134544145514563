var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    { ref: "modal", attrs: { title: _vm.title } },
    [
      _c("select-user", {
        attrs: {
          "account-id": _vm.accountId,
          label: "Assign Publisher to " + _vm.contentType.name,
          filters: _vm.userFilters,
          "filter-ids": _vm.assignedUserIds,
          outlined: "",
          object: "",
        },
        on: { change: _vm.assignUser },
      }),
      _c("loader", { attrs: { task: _vm.assignTask } }),
      _c("loader", { attrs: { task: _vm.unassignTask } }),
      _c(
        "q-markup-table",
        { staticClass: "q-table-cell-vcenter q-mt-md", attrs: { flat: "" } },
        [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v("Publisher")]),
              _c("th", [_vm._v("Assigned At")]),
              _c("th"),
            ]),
            !_vm.contentType.users.length
              ? _c("tr", [
                  _c(
                    "td",
                    { attrs: { colspan: "3" } },
                    [
                      _c("message", [
                        _vm._v("\n          No users assigned to "),
                        _c("em", [_vm._v(_vm._s(_vm.contentType.name))]),
                        _vm._v(" content type.\n        "),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _c(
            "tbody",
            _vm._l(_vm.contentType.users, function (ctUser) {
              return _c("tr", { key: ctUser.id }, [
                _c("td", [
                  _vm._v(
                    _vm._s(ctUser.user.firstName) +
                      " " +
                      _vm._s(ctUser.user.lastName)
                  ),
                ]),
                _c("td", [
                  _vm._v(_vm._s(_vm._f("datetime")(ctUser.assignedAt))),
                ]),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("q-btn", {
                      attrs: {
                        icon: "fa fa-times",
                        title: "Unassign publisher from this content type.",
                        flat: "",
                        round: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.unassignUser(ctUser)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }