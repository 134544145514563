


























































import {
  HasTaskMixin, ShowFinally, Message, HumanizeTime,
} from 'mycorems-app-shared';
import { QPagination, QueryResult } from '@/types';
import { Template, TemplateFilters } from '../types';
import repository from './template-repository';
import ContentTypeLabel from '../content-type/ContentTypeLabel.vue';
import SaveTemplateBtn from './SaveTemplateBtn.vue';
import DeleteTemplateBtn from './DeleteTemplateBtn.vue';
import RouterLink from '../routing/RouterLink.vue';

interface Data {
  templates: Template[],
  pagination: QPagination,
}

export default HasTaskMixin.extend({
  components: {
    ContentTypeLabel,
    SaveTemplateBtn,
    DeleteTemplateBtn,
    RouterLink,
    HumanizeTime,
    ShowFinally,
    Message,
  },
  props: {
    contentTypeId: Number,
    accountId: String,
  },
  data(): Data {
    return {
      templates: [],
      pagination: {
        rowsPerPage: 5,
        page: 1,
        rowsNumber: 0,
      },
    };
  },
  computed: {
    columns(): object[] {
      return [
        {
          name: 'contentType',
          label: 'Content Type',
          align: 'left',
        },
        {
          name: 'type',
          label: 'Type',
          align: 'left',
          field: (row: Template) => row.documentType.name,
        },
        {
          name: 'name',
          label: 'Name',
          align: 'left',
        },
        {
          name: 'createdAt',
          label: 'Created',
          align: 'left',
        },
        {
          name: 'actions',
          align: 'right',
        },
      ];
    },
    offset(): number {
      return ((this.pagination.page - 1) * this.pagination.rowsPerPage);
    },
    filters(): TemplateFilters {
      return {
        contentTypeId: this.contentTypeId,
        accountId: this.accountId,
        offset: this.offset,
        limit: this.pagination.rowsPerPage,
      };
    },
  },
  watch: {
    filters: {
      handler(): void {
        this.load();
      },
      deep: true,
    },
  },
  methods: {
    add(template: Template): void {
      this.templates.unshift(template);
    },
    update(template: Template): void {
      const index = this.findIndex(template);
      if (index >= 0) {
        this.templates.splice(index, 1, template);
      }
    },
    remove(template: Template): void {
      const index = this.findIndex(template);
      if (index >= 0) {
        this.templates.splice(index, 1);
        this.pagination.rowsNumber -= 1;
      }
    },
    findIndex(template: Template): number {
      return this.templates.findIndex((t: Template) => t.id === template.id);
    },
    load(): void {
      this.task = repository.findAll(this.filters);
      this.task.then((result: QueryResult) => {
        this.templates = (result.items as Template[]);
        this.pagination.rowsNumber = result.total;
      });
    },
  },
  created(): void {
    this.load();
  },
});
