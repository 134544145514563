import { AxiosResponse } from 'axios';
import api from '../API';
import { QueryResult } from '@/types';
import { Template, TemplateFilters } from '../types';

export default {
  find(id: string): Promise<Template> {
    return api
      .get(`templates/${id}`)
      .then(response => response.data);
  },
  findAll(filters: TemplateFilters): Promise<QueryResult<Template>> {
    return api
      .get('templates', { params: filters })
      .then((response: AxiosResponse) => ({
        total: parseInt(response.headers['x-total-items'], 10),
        items: response.data,
      }));
  },
  findAvailable(accountId: string|null, contentTypeId: number|null): Promise<Template[]> {
    return api
      .get('available-templates', { params: { accountId, contentTypeId } })
      .then((response: AxiosResponse) => response.data);
  },
};
