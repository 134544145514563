var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.fields, function (field) {
      return _c(
        "div",
        { key: field.id, staticClass: "q-px-sm q-pt-sm" },
        [
          _c("editor-field", {
            attrs: {
              field: field,
              "track-changes": _vm.trackChanges,
              "manage-tracked-changes": _vm.manageTrackedChanges,
              "shared-toolbar": _vm.sharedToolbar,
              user: _vm.user,
            },
            on: { change: _vm.updateField },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }