import { AxiosResponse } from 'axios';
import api from '../API';
import { Document, DocumentFilters } from '../types';

export default {
  find(id: string): Promise<Document> {
    return api
      .get(`documents/${id}`)
      .then(response => response.data);
  },
  findAll(filters: DocumentFilters): Promise<Document[]> {
    return api
      .get('documents', { params: filters })
      .then((response: AxiosResponse) => ({
        total: parseInt(response.headers['x-total-items'], 10),
        items: response.data,
      }));
  },
};
