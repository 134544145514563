










import Vue from 'vue';
import { openModal, notify } from 'mycorems-app-shared';
import isGranted from '../authorization/isGranted.js';
import { Template, SaveTemplateModalOKEvent } from '../types';
import SaveTemplateModal from './SaveTemplateModal.vue';

export default Vue.extend({
  props: {
    accountId: String,
    template: Object as () => Template,
    label: String,
    icon: String,
  },
  methods: {
    create(): void {
      openModal(SaveTemplateModal, {
        parent: this,
        accountId: this.accountId,
        template: this.template,
      }).onOk((event: SaveTemplateModalOKEvent) => {
        if (event.created) {
          notify.success('Template created.');
          this.$emit('created', event.created);
        }
        if (event.edited) {
          notify.success('Template updated.');
          this.$emit('edited', event.edited);
        }
      });
    },
    isGranted,
  },
});
