var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("full-height-container", [
    _c("div", { staticClass: "row full-height" }, [
      _c("div", { staticClass: "col-12 col-md-6 offset-md-3 full-height" }, [
        _c(
          "div",
          {
            staticClass:
              "bg-white shadow-2 full-height overflow-auto custom-scrollbar",
          },
          [_vm._t("default")],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }