

















import Vue from 'vue';
import { Document } from '../types';
import EditorToolbar from '../editor/EditorToolbar.vue';
import DocumentActionDropdown from './status/DocumentActionDropdown.vue';

export default Vue.extend({
  components: {
    EditorToolbar,
    DocumentActionDropdown,
  },
  props: {
    document: {
      type: Object as () => Document,
      required: true,
    },
    sharedEditorToolbar: {
      type: Boolean,
      default: false,
    },
  },
});
