















import { HasTaskMixin, Loader } from 'mycorems-app-shared';
import { Template } from '../types';
import repository from './template-repository';
import { updateFields } from './template-service';
import EditorToolbar from '../editor/EditorToolbar.vue';
import AutoSaveDocEditor from '../editor/AutoSaveDocEditor.vue';

interface Data {
  template: Template|null,
}

export default HasTaskMixin.extend({
  components: {
    Loader,
    EditorToolbar,
    AutoSaveDocEditor,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data(): Data {
    return {
      template: null,
    };
  },
  computed: {
    headings(): string[] {
      if (!this.template) return [];
      const headings: string[] = [this.template.contentType.name];
      if (!this.template.name) return headings;
      headings.unshift(this.template.name);
      return headings;
    },
  },
  watch: {
    id: {
      handler(): void {
        this.load();
      },
      immediate: true,
    },
  },
  methods: {
    load(): void {
      this.task = repository.find(this.id);
      this.task.then((template: Template) => {
        this.template = template;
      });
    },
    updateFields,
  },
});
