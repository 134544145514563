import api from '../API';
import { AccountConfig } from '../types';

export default {
  find(id: string): Promise<AccountConfig> {
    return api
      .get(`accounts/${id}/config`)
      .then(response => response.data);
  },
};
