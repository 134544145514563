







import Vue from 'vue';
import { openModal } from 'mycorems-app-shared';
import { ContentType, ContentTypeUserModalOkEvent } from '../types';
import ContentTypeUsersModal from './ContentTypeUsersModal.vue';

export default Vue.extend({
  props: {
    contentType: {
      type: Object as () => ContentType,
      required: true,
    },
  },
  methods: {
    openModal(): void {
      openModal(ContentTypeUsersModal, {
        contentType: this.contentType,
      }).onOk((e: ContentTypeUserModalOkEvent) => {
        if (e.assigned) this.$emit('assigned', e.assigned);
        if (e.unassigned) this.$emit('unassigned', e.unassigned);
      });
    },
  },
});
