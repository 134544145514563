








import Vue from 'vue';
import { openModal, notify } from 'mycorems-app-shared';
import isGranted from '../authorization/isGranted.js';
import { ContentType } from '../types';
import SaveContentTypeModal from './SaveContentTypeModal.vue';

export default Vue.extend({
  props: {
    contentType: Object as () => ContentType,
  },
  methods: {
    add(): void {
      openModal(SaveContentTypeModal, {
        parent: this,
        contentType: this.contentType,
      }).onOk((contentType: ContentType) => {
        if (!this.contentType) {
          this.$emit('added', contentType);
          notify.success(`${contentType.name} content type added.`);
        } else {
          this.$emit('updated', contentType);
          notify.success(`${contentType.name} content type updated.`);
        }
      });
    },
    isGranted,
  },
});
