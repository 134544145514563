import { recognition } from 'mycorems-app-accounts';
import api from '../API';
import { Field, Document } from '../types';

export function create(
  documentTypeId: string, contentItemId: number,
  title: string, fields: Field[], status: number,
): Promise<Document> {
  return api
    .post(`content-items/${contentItemId}/documents`, {
      documentTypeId,
      title,
      fields,
      status,
    }).then();
}

export function edit(id: string, title: string, status: number): Promise<void> {
  return api
    .put(`documents/${id}`, {
      title,
      status,
    }).then();
}

export function deleteDocument(id: string): Promise<void> {
  return api
    .delete(`documents/${id}`)
    .then();
}

export function updateFields(id: string, fields: Field[]): Promise<Field[]> {
  return api
    .put(`documents/${id}/fields`, { fields })
    .then(response => response.data);
}

export function submit(id: string): Promise<Document> {
  const submittedBy = recognition.getUserId();
  return api
    .post(`documents/${id}/submit`, { submittedBy })
    .then(response => response.data);
}


export function approve(id: string): Promise<Document> {
  const approvedBy = recognition.getUserId();
  return api
    .post(`documents/${id}/approve`, { approvedBy })
    .then(response => response.data);
}

export function nudge(id: string): Promise<void> {
  return api
    .put(`documents/${id}/nudge`)
    .then();
}
