


































import { HasTaskMixin } from 'mycorems-app-shared';
import { Template } from '../types';
import repository from './template-repository';
import TemplateItem from './TemplateItem.vue';

interface Data {
  templates: Template[],
}

export default HasTaskMixin.extend({
  components: { TemplateItem },
  model: {
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: 'Select Template',
    },
    value: Object as () => Template,
    accountId: String,
    contentTypeId: Number,
  },
  data(): Data {
    return {
      templates: [],
    };
  },
  computed: {
    options(): object[] {
      let options: object[] = [];
      const globalTemplates = this.templates.filter((t: Template) => t.accountId === null);
      if (globalTemplates.length) {
        options.push({ group: 'Global' });
        options = options.concat(globalTemplates);
      }
      const accountTemplates = this.templates.filter((t: Template) => t.accountId !== null);
      if (!accountTemplates.length) return options;
      options.push({ group: 'Agency' });
      options = options.concat(accountTemplates);
      return options;
    },
  },
  watch: {
    accountId: {
      handler(): void {
        this.load();
      },
      immediate: true,
    },
  },
  methods: {
    load(): void {
      this.task = repository.findAvailable(this.accountId, this.contentTypeId);
      this.task.then((templates: Template[]) => {
        this.templates = templates;
      });
    },
  },
});
