


































import { HasTaskMixin, Loader } from 'mycorems-app-shared';
import { AccountType } from 'mycorems-app-accounts';
import { Document, User, Account } from '../types';
import repository from './document-repository';
import { updateFields } from './document-service';
import { isSubmitted, isApproved } from '.';
import DocumentToolbar from './DocumentToolbar.vue';
import AutoSavingDocEditor from '../editor/AutoSaveDocEditor.vue';
import UserLoader from '../user/UserLoader.vue';
import AccountLoader from '../account/AccountLoader.vue';

interface Data {
  document: Document|null,
  user: User|null,
  userAccount: Account|null,
}

export default HasTaskMixin.extend({
  components: {
    Loader,
    DocumentToolbar,
    AutoSavingDocEditor,
    UserLoader,
    AccountLoader,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    userId: String,
  },
  data(): Data {
    return {
      document: null,
      user: null,
      userAccount: null,
    };
  },
  computed: {
    documentHeadings(): string[] {
      if (!this.document) return [];
      const { contentItem } = this.document;
      return [
        contentItem.account.name,
        this.document.title,
        contentItem.contentType.name,
      ];
    },
    canTrackChanges(): boolean {
      if (!this.document) return false;
      return (isSubmitted(this.document) && !isApproved(this.document));
    },
    canManageTrackedChanges(): boolean {
      if (!this.userAccount) return false;
      return (this.canTrackChanges && !AccountType.isClient(this.userAccount.type));
    },
  },
  watch: {
    id: {
      handler(): void {
        this.load();
      },
      immediate: true,
    },
  },
  methods: {
    update(document: Document): void {
      this.document = document;
    },
    load(): void {
      this.task = repository.find(this.id);
      this.task.then((document: Document) => {
        this.document = document;
      });
    },
    updateFields,
  },
});
