














import Vue from 'vue';

export default Vue.extend({
  components: {
    StickyPageToolbar: () => import('mycorems_app/layout/StickyPageToolbar'),
    FroalaToolbar: () => import('mycorems_app/form/FroalaToolbar'),
  },
  props: {
    sharedToolbar: {
      type: Boolean,
      default: false,
    },
  },
});
