import VueRouter from 'vue-router';
import { Document } from '../types';
import config from '../config';

export function viewDocInNewTab(accountId: string, id: string, router: VueRouter): void {
  const route = router.resolve({
    path: `${config.URL_PREFIX}/accounts/${accountId}/documents/${id}`,
  });
  window.open(route.href, '_blank');
}

export function isSubmitted(document: Document): boolean {
  return (document.lastSubmittedAt !== null);
}

export function isApproved(document: Document): boolean {
  return (document.approvedAt !== null);
}
