var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isGranted("SUBMIT_DOCUMENT")
    ? _c(
        "q-btn",
        _vm._b(
          { attrs: { label: _vm.label }, on: { click: _vm.submitWithConfirm } },
          "q-btn",
          _vm.$attrs,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }