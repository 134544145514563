










import Vue from 'vue';
import isGranted from '../authorization/isGranted.js';
import { openModal } from 'mycorems-app-shared';
import WriteContentModal from './WriteContentModal.vue';

export default Vue.extend({
  props: {
    label: {
      type: String,
      default: 'Chat With AI',
    },
    icon: String,
  },
  methods: {
    open(): void {
      openModal(WriteContentModal, {
        parent: this,
      }).onOk((content: string) => {
        this.$emit('write', content);
      });
    },
    isGranted,
  },
});
