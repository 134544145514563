import promiseFinally from 'promise.prototype.finally';
import Vue from 'vue';
import './quasar';
import Buefy from 'buefy';
import moment from 'moment';
import App from './App.vue';
import router from './routing/router';

promiseFinally.shim();

Vue.config.productionTip = false;

Vue.use(Buefy, {
  defaultIconPack: 'fa',
});

Vue.filter('datetime', datetime => moment(datetime).format('MMM Do YYYY h:mma'));

new Vue({
  // @ts-ignore
  router,
  render: h => h(App),
}).$mount('#app');
