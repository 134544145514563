


















import Vue from 'vue';
import { Template } from '../types';

export default Vue.extend({
  props: {
    template: {
      type: Object as () => Template,
      required: true,
    },
  },
  computed: {
    label(): string {
      return (this.template.name !== null) ? this.template.name : 'Default';
    },
  },
});
