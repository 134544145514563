










import { HasTaskMixin, ResourceLoader } from 'mycorems-app-shared';
import { User } from '../types';
import repository from './user-repository';

interface Data {
  task: Promise<User>|undefined,
}

export default HasTaskMixin.extend({
  components: { ResourceLoader },
  model: {
    prop: 'user',
    event: 'loaded',
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    user: Object as () => User,
  },
  data(): Data {
    return {
      task: undefined,
    };
  },
  watch: {
    userId: {
      handler() {
        this.load();
      },
      immediate: true,
    },
  },
  methods: {
    load() {
      this.task = repository.find(this.userId);
      this.task.then((user) => {
        this.$emit('loaded', user);
      });
    },
  },
});
