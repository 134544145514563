var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      ref: "modal",
      attrs: { title: _vm.title },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("async-btn", {
                attrs: {
                  task: _vm.task,
                  label: _vm.saveBtnLabel,
                  color: "primary",
                  disable: !_vm.canSave,
                },
                on: { click: _vm.save },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "q-gutter-md" },
        [
          _c("q-input", {
            attrs: { label: "Name", outlined: "" },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
          _c(
            "div",
            { staticClass: "flex items-center" },
            [
              _c("strong", [_vm._v("Choose a colour for this Content Type.")]),
              _c("color-picker", {
                staticClass: "on-right",
                model: {
                  value: _vm.color,
                  callback: function ($$v) {
                    _vm.color = $$v
                  },
                  expression: "color",
                },
              }),
            ],
            1
          ),
          _c("content-type-duration-input", {
            model: {
              value: _vm.devDuration,
              callback: function ($$v) {
                _vm.devDuration = $$v
              },
              expression: "devDuration",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }