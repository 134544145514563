
































import Vue from 'vue';
import { Document } from '../../types';
import { isApproved } from '.';
import DocumentStatus from './DocumentStatus.vue';
import SubmitDocumentBtn from './SubmitDocumentBtn.vue';
import ApproveDocumentBtn from './ApproveDocumentBtn.vue';

export default Vue.extend({
  components: {
    DocumentStatus,
    SubmitDocumentBtn,
    ApproveDocumentBtn,
  },
  props: {
    document: {
      type: Object as () => Document,
      required: true,
    },
  },
  computed: {
    isApproved(): boolean {
      return isApproved(this.document);
    },
  },
});
