import api from '@/API';
import { User } from '@/types';

export default {
  find(id: string): Promise<User> {
    return api
      .get(`users/${id}`)
      .then(response=> response.data);
  },
};
