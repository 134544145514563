import { AccountType } from 'mycorems-app-accounts';

export default {
  MANAGE_ACCOUNT_CONFIG: {
    accountTypes: [AccountType.SERVICE_PROVIDER, AccountType.AGENCY],
    scope: 'manager',
  },
  VIEW_CALENDAR: {
    scope: 'read',
  },
  VIEW_CLIENTS: {
    accountTypes: [AccountType.SERVICE_PROVIDER, AccountType.AGENCY],
    scope: 'write',
  },
  PUBLISH_CONTENT: {
    accountTypes: [AccountType.SERVICE_PROVIDER, AccountType.AGENCY],
    scope: 'publish',
  },
  EDIT_CALENDAR: {
    accountTypes: [AccountType.SERVICE_PROVIDER, AccountType.AGENCY],
    scope: 'write',
  },
  VIEW_CONTENT_ITEM_STATUSES: {
    accountTypes: [AccountType.SERVICE_PROVIDER, AccountType.AGENCY],
  },
  CREATE_CONTENT: {
    accountTypes: [AccountType.SERVICE_PROVIDER, AccountType.AGENCY],
    scope: 'write',
  },
  EDIT_CONTENT: {
    scope: 'write',
  },
  SETUP_CONTENT_TYPES: {
    accountTypes: [AccountType.SERVICE_PROVIDER],
    scope: 'manager',
  },
  SAVE_CONTENT_TYPE: {
    accountTypes: [AccountType.SERVICE_PROVIDER],
    scope: 'manager',
  },
  ENABLE_DISABLE_CONTENT_TYPE: {
    accountTypes: [AccountType.SERVICE_PROVIDER],
    scope: 'manager',
  },
  CREATE_TEMPLATE: {
    accountTypes: [AccountType.SERVICE_PROVIDER, AccountType.AGENCY],
    scope: 'manager',
  },
  MANAGE_CONTENT: {
    accountTypes: [AccountType.SERVICE_PROVIDER, AccountType.AGENCY],
    scope: 'manager',
  },
  CREATE_DOCUMENT: {
    accountTypes: [AccountType.SERVICE_PROVIDER, AccountType.AGENCY],
    scope: 'write',
  },
  EDIT_DOCUMENT_DETAILS: {
    accountTypes: [AccountType.SERVICE_PROVIDER, AccountType.AGENCY],
    scope: 'write',
  },
  DELETE_DOCUMENT: {
    accountTypes: [AccountType.SERVICE_PROVIDER, AccountType.AGENCY],
    scope: 'write',
  },
  SUBMIT_DOCUMENT: {
    scope: 'write',
  },
  APPROVE_DOCUMENT: {
    scope: 'write',
  },
  ADD_COMMENT: {
    scope: 'write',
  },
};
