














































import _ from 'lodash';
import { Modal, ModalMixin, AsyncBtn } from 'mycorems-app-shared';
import { Template, Field, DocumentType } from '../types';
import { create, updateFields } from './template-service';
import SelectAvailableTemplate from './SelectAvailableTemplate.vue';
import SelectDocumentType from '../document-type/SelectDocumentType.vue';
import SelectContentType from '../content-type/SelectContentType.vue';
import AddFields from './field/AddFields.vue';

interface Data {
  selectedTemplate: Template|null,
  documentTypeId: string|null,
  contentTypeId: number|null,
  fields: Field[],
  name: string|null,
  task: Promise<Template>|Promise<Field[]>|null,
}

export default ModalMixin.extend({
  components: {
    Modal,
    SelectAvailableTemplate,
    SelectDocumentType,
    SelectContentType,
    AddFields,
    AsyncBtn,
  },
  props: {
    accountId: String,
    template: Object as () => Template,
  },
  data(): Data {
    return {
      selectedTemplate: null,
      documentTypeId: (this.template) ? this.template.documentType.id : null,
      contentTypeId: (this.template) ? this.template.contentType.id : null,
      fields: (this.template) ? this.template.fields : [],
      name: (this.template) ? this.template.name : null,
      task: null,
    };
  },
  computed: {
    title(): string {
      return (this.hasTemplate) ? 'Edit Template' : 'Create Template';
    },
    saveBtnLabel(): string {
      return (this.hasTemplate) ? 'Edit' : 'Create';
    },
    hasTemplate(): boolean {
      return (this.template !== undefined);
    },
    canSave(): boolean {
      return (
        (this.contentTypeId !== null)
        && (this.fields.length > 0)
      );
    },
  },
  methods: {
    selectTemplate(template: Template): void {
      this.selectedTemplate = template;
      this.contentTypeId = template.contentType.id;
      this.documentTypeId = template.documentType.id;
      this.name = template.name;
      this.fields = template.fields;
    },
    selectDocumentType(documentType: DocumentType): void {
      this.documentTypeId = documentType.id;
    },
    save(): void {
      if (this.hasTemplate) {
        this.edit();
      } else {
        this.create();
      }
    },
    create(): void {
      if (!this.documentTypeId || !this.contentTypeId) return;
      this.task = create(
        this.documentTypeId,
        this.contentTypeId,
        this.accountId,
        this.name,
        this.fields,
      );
      this.task.then((template: Template) => {
        this.$emit('ok', { created: template });
        this.hide();
      });
    },
    edit(): void {
      if (!this.template) return;
      this.task = updateFields(this.template.id, this.fields);
      this.task.then((fields: Field[]) => {
        const template: Template = _.cloneDeep(this.template);
        template.fields = fields;
        this.$emit('ok', { edited: template });
        this.hide();
      });
    },
  },
});
